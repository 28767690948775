import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Breadcaump from "../../components/Breadcaump";
import BlogPost from "../../blog/components/BlogPost";
import Login from "../../components/Login";
import DataTable from "react-data-table-component";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Col,
  Progress,
  ModalFooter
} from "reactstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import storage from "./firebase";
import {encrypt, url} from '../../brosky';
import Axios from "axios";
class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    // Update the loader's progress.
    // server.onUploadProgress( data => {
    //     loader.uploadTotal = data.total;
    //     loader.uploaded = data.uploaded;
    // } );

    // Return a promise that will be resolved when the file is uploaded.
    // return loader.file
    //     .then( file => server.upload( file ) );
    // return 'https://www.google.com/url?sa=i&source=images&cd=&ved=2ahUKEwj0wtLfrcnmAhXYdn0KHTI-BsEQjRx6BAgBEAQ&url=https%3A%2F%2Fwww.pexels.com%2Fsearch%2Fbeauty%2F&psig=AOvVaw0gfOM1vA_7XJKB9SblXv7N&ust=1577107591488369';
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          var d = new Date();
          var n = d.getTime();
          let url;
          const uploadTask = storage.ref(`${n}`).put(file);
          uploadTask.on(
            "state_changed",
            async snapshot => {
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              // await this.setState({ progress: this.state.progress + (progress/100*67)});
            },
            error => {
              // Handle unsuccessful uploads
              console.log(error);
            },
            () => {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function(downloadURL) {
                  console.log("File available at", downloadURL);
                  resolve({ default: downloadURL });
                });
            }
          );
        })
    );
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    // server.abortUpload();
  }
}
const uid={};
export default class Approve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "ID", selector: "id", sortable: true, maxWidth: '50px' },
        { name: "Judul", selector: "nama", sortable: true },
        // { name: "Preview", selector: "berita", sortable: true, maxWidth: '350px',style:{maxHeight: '10px'},cell: (row)=>{return(
        //   <div dangerouslySetInnerHTML={{ __html: row.berita }} />
        // )
        // } },
        { name: "Status", selector: "active", sortable: true, maxWidth: '150px', cell: (row)=>{
          return(
            <p className={row.active=='A'? 'Active':row.active=='I'?'Inactive':'Rejected'}>{row.active=='A'? 'Active':row.active=='I'?'Inactive':'Rejected'}</p>
          )
        } },
        { name: "Last Approval", selector: "last_approval_role", sortable: true, maxWidth: '150px' },
        { name: "Author", selector: "create_by_name", sortable: true, maxWidth: '150px' },
        { name: "Divisi", selector: "divisi", sortable: true, maxWidth: '150px' },
        { name: "Last Update", selector: "update_date", sortable: true, maxWidth: '150px' },
        { name: "Is Private?", selector: "is_private", sortable: true, maxWidth: '150px', cell:(row)=>{
          return(
            <p>{row.is_private=='Y'?'Yes':'No'}</p>
          )
        } },
        { name: "Update By", selector: "update_by_name", sortable: true, maxWidth: '150px' },
        {
          name: "Actions",
          selector: "actions",
          button: true,
          ignoreRowClick: true, maxWidth: '250px'
        }
      ],
      data: [],
      isOpen: false,
      isOpenView: false,
      judul: "",
      artikel: "",
      thumbnail: "",
      is_private: "N",
      start_upload: false,
      progress: 0,
      berita_active: 0,
      berita_id: 0
    };
  }
  componentDidMount(){
    let x = JSON.parse(localStorage.getItem("@uid"));
    uid.id = x[0].id;
    uid.divisi_id = x[0].divisi_id;
    uid.role_id = x[0].role_id;
    let query = "select * from view_berita";
    if(uid.role_id <3) query+= " where divisi_id='"+ uid.divisi_id + "'";
    query += " order by create_date desc";
    query = encrypt(query);
    Axios.post(url.insert,{
      query: query
    }).then(result=>{
      //console.log(result.data);
      result.data.map((value, index)=>{
        result.data[index].actions = <Button color="primary" onClick={()=>this.custom_click(index,value.id)}>View</Button>;
      });
      this.setState({ data: result.data});
    }).catch(err=>console.log(err));
  }
  update_progress=(value)=>{
    this.setState({ progress: value});
  }
  tambah = async () => {
    if(this.state.judul.length < 10) alert("Judul artikel harus mengandung setidaknya 10 karakter.");
    else if(this.state.thumbnail=='') alert("Anda harus memasukan thumbnail terlebih dahulu.");
    else if(this.state.artikel.length < 50) alert("Artikel terlalu pendek.");
    else {
      await this.setState({ start_upload: true});
      var d = new Date();
      var n = d.getTime();
      const uploadTask = storage.ref(`thumbnail/${n}`).put(this.state.thumbnail);
      let currentState = this.state;
      let currentthis=this;
      uploadTask.on(
        "state_changed",
        async snapshot => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          await currentthis.update_progress(progress);
          // await this.setState({ progress: this.state.progress + (progress/100*67)});
        },
        error => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            console.log("File available at", downloadURL);
            let status='';
            if(uid.role_id == 4) status='A';
            else status='I';
            let query = `insert into tbl_berita (nama, berita, last_approval_role_id, previous_version_id, divisi_id, url_foto, create_by, update_by, is_private, active) 
            values('${currentState.judul}', '${currentState.artikel}', ${uid.role_id}, 0, ${uid.divisi_id}, '${downloadURL}', ${uid.id}, ${uid.id}, '${currentState.is_private}', '${status}')`;
            ////console.log(query);
            query = encrypt(query);
            Axios.post(url.insert,{
              query: query
            }).then(result=>{
              alert('Data Berhasil disimpan');
              window.location.reload();
            }).catch(err=>{
              console.log(err);
            });
          });
        }
      );
    }
  };
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      nama: "",
      modalUpdate: false
    });
  };
  edit=()=>{
    this.setState({ judul: this.state.data[this.state.berita_active].nama, artikel: this.state.data[this.state.berita_active].berita})
    this.toggle();
  }
  toggleView = () => {
    this.setState({
      isOpenView: !this.state.isOpenView
    });
  };
  custom_click=(index,berita_id)=>{
    this.setState({ berita_active: index, berita_id: berita_id, isOpenView: true});
  }
  approve=()=>{
    let status='';
    if(uid.role_id == 4) status='A';
    else status='I';
    let x=this.state.data[this.state.berita_active].id;
    let query=`update tbl_berita set last_approval_role_id=${uid.role_id}, update_by=${uid.id}, update_date=now(), active='${status}' where id = ${x};`;
    query = encrypt(query);
    Axios.post(url.insert,{
      query: query
    }).then(result=>{
      if(uid.role_id==4) {
        query=` update tbl_berita set active='I' where id=${this.state.data[this.state.berita_active].previous_version_id};`;
        query = encrypt(query);
        Axios.post(url.insert,{
          query: query
        }).then(result=>{
          alert('Data Berhasil disimpan');
          window.location.reload();
        });
      }
    }).catch(err=>{
      console.log(err);
    });
  }
  reject=()=>{
    let status='';
    if(uid.role_id == 4) status='A';
    else status='I';
    let x=this.state.data[this.state.berita_active].id;
    let query=`update tbl_berita set update_by=${uid.id}, update_date=now(), active='R' where id = ${x}`;
    query = encrypt(query);
    Axios.post(url.insert,{
      query: query
    }).then(result=>{
      alert('Data Berhasil disimpan');
      window.location.reload();
    }).catch(err=>{
      console.log(err);
    });
  }
  render() {
    return (
      <div className="datatable-container">
        <DataTable
          title="Approval"
          columns={this.state.columns}
          data={this.state.data}
          striped
          highlightOnHover
          pagination
          style={{ backgroundColor: "#ffffff" }}
        />
        <Modal isOpen={this.state.isOpen} toggle={this.state.toggle} size="lg">
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="exampleEmail" sm={2}>
                  Judul
                </Label>
                <Col sm={10}>
                  <Input
                    type="text"
                    name="nama"
                    placeholder="Judul Artikel"
                    onChange={input =>
                      this.setState({ judul: input.target.value })
                    }
                    value={this.state.judul}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleEmail" sm={2}>
                  Thumbnail
                </Label>
                <Col sm={10}>
                  <Input
                    type="file"
                    placeholder="Judul Artikel"
                    onChange={input =>
                      this.setState({ thumbnail: input.target.files[0] })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleEmail" sm={2}>
                  Private?
                </Label>
                <Col sm={10}>
                  <Input
                    type="checkbox"
                    name="is_private"
                    onChange={async(input) =>{
                      await this.state.is_private == 'Y' ? this.setState({ is_private: 'N'}):this.setState({ is_private: 'Y'});
                      console.log(this.state.is_private);
                    }
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={this.state.artikel}
                    onInit={editor => {
                      editor.plugins.get(
                        "FileRepository"
                      ).createUploadAdapter = function(loader) {
                        console.log("loader: " + JSON.stringify(loader));
                        return new MyUploadAdapter(loader);
                      };
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({ artikel: data});
                    }}
                  />
                </Col>
              </FormGroup>
              {this.state.start_upload? 
                <FormGroup>
                  <Progress value={this.state.progress}/>
                </FormGroup>
                : false
              }
              <FormGroup row>
                <Col sm={10}>
                  {this.state.modalUpdate ? (
                    <Button onClick={this.update}>Update</Button>
                  ) : (
                    <Button onClick={this.tambah}>Tambah</Button>
                  )}
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {this.state.data.length > 0? 
        <Modal isOpen={this.state.isOpenView} toggle={this.state.toggleView} size="lg">
          <ModalHeader toggle={this.toggleView}>{this.state.data[this.state.berita_active].nama}</ModalHeader>
          <ModalBody>
          <Form>
              <FormGroup row>
                <Label for="exampleEmail" sm={2}>
                  Thumbnail
                </Label>
                <Col sm={10}>
                  <img src={this.state.data[this.state.berita_active].url_foto} className="thumbnail"/>
                </Col>
              </FormGroup>
            </Form>
            <h4>Informasi: </h4>
            <div dangerouslySetInnerHTML={{ __html: this.state.data[this.state.berita_active].berita }} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleView}>Exit</Button>
            {this.state.data[this.state.berita_active].last_approval_role_id<uid.role_id? 
              <Button color="danger" onClick={this.reject}>Reject</Button>:false}
            {this.state.data[this.state.berita_active].last_approval_role_id<uid.role_id? 
              <Button color="primary" onClick={this.approve}>Approve</Button>:false}
            {uid.role_id==4 && this.state.data[this.state.berita_active].active=='A'? <Button color="danger" onClick={this.reject}>Reject</Button>:false}
          </ModalFooter>
        </Modal>
        :false}
      </div>
    );
  }
}
