var crypto = require('crypto');
let key = 'kmskwikkiangie12';
let iv = 'kmskwikkiangie23';
export const encrypt=(text)=>{
  var cipher = crypto.createCipheriv("aes-128-cbc",key, iv);
  var crypted = cipher.update(text, 'utf8', 'binary');
  var cbase64  = Buffer.from(crypted, 'binary').toString('base64');
  crypted += cipher.final('binary');
  crypted = Buffer.from(crypted, 'binary').toString('base64');
  return crypted;
}
export const url={
    insert: 'https://apijoa.sonlim.com/apiInsert/'
}
