import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Breadcaump from "../../components/Breadcaump";
import Login from "../../components/Login";
import { Redirect, Route, Switch } from 'react-router-dom';
import Logout from "../Admin/Logout";
import CardBerita from "./CardBerita";
import { encrypt, url } from "../../brosky";
import Axios from "axios";
import CardLogin from "../../components/CardLogin";
import CardHome from "../../components/CardHome";

let uid={}
export default class HomeContainer extends Component{
    constructor(props){
        super(props);
        this.state={
            data:[
                {
                    nama: 'Umum',
                    url: 'umum',
                    divisi_id: 1
                },
                {
                    nama: 'ICT',
                    url: 'ict',
                    divisi_id: 2
                },
                {
                    nama: 'BAK',
                    url: 'bak',
                    divisi_id: 3
                },
                {
                    nama: 'Pembelian dan Percetakan',
                    url: 'pembelian',
                    divisi_id: 4
                },
                {
                    nama: 'Personalia',
                    url: 'personalia',
                    divisi_id: 5
                },
                {
                    nama: 'Rumah Tangga',
                    url: 'rumah_tangga',
                    divisi_id: 6
                },
                {
                    nama: 'Satuan Pengamanan',
                    url: 'satpam',
                    divisi_id: 7
                },
                {
                    nama: 'Operator Telephone',
                    url: 'operator_telephone',
                    divisi_id: 8
                },
            ]
        }
    }
    componentDidMount(){
        
    }

    render(){
        let val = {}
        return(
            <React.Fragment>
                <div style={{width: '100%', textAlign: 'center', paddingTop: 50, paddingBottom: 50}}>
                    <h3>HOME</h3>
                </div>
                <div className="row center">
                    {this.state.data.map((value, index)=>{
                        return(
                            <CardHome data={value}/>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }
}