import firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAXxISGXjF-h2ovSLF-QB_6f3PgbUVB3wY",
    authDomain: "kms-ibikkg.firebaseapp.com",
    databaseURL: "https://kms-ibikkg.firebaseio.com",
    projectId: "kms-ibikkg",
    storageBucket: "kms-ibikkg.appspot.com",
    messagingSenderId: "253995738682",
    appId: "1:253995738682:web:fae4bca28ae1b03ef6f703",
    measurementId: "G-58CG3VB61Q"
};
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { firebase, storage as default };