import React, { Component } from "react";
import Breadcaump from "../../components/Breadcaump";
import BlogPost from "../../blog/components/BlogPost";
import Login from "../../components/Login";
import {Navbar, NavbarToggler, Collapse, Nav, NavItem, NavLink, NavbarBrand, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText} from 'reactstrap';
import {Switch, Route} from 'react-router-dom';
import Artikel from "./Artikel";
import Approve from "./Approve";
import User from "./User";
import Dashboard from "./Dashboard";
let uid={};
export default class Admin extends Component{
    
    constructor(props){
        super(props);
        this.state={
            isOpen: false,
            role_id: 0
        }
    }
    
    toggle=()=>{
        this.setState({ isOpen: !this.state.isOpen});
    }
    componentDidMount(){
      if(localStorage.getItem("@uid") == null) this.props.history.push("/login");
      let x = JSON.parse(localStorage.getItem("@uid"));
      if(x==null) uid={};
      else {
        uid.role_id=x[0].role_id;
        this.setState({ role_id: x[0].role_id});
      }
    }

    render(){
        return(
            <div>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">Kwik Kian Gie SOB</NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/#/admin/">Dashboard</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#/admin/artikel/">Artikel</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#/admin/approve/">Approve</NavLink>
            </NavItem>
            {this.state.role_id==4?
            <NavItem>
              <NavLink href="/#/admin/user/">User</NavLink>
            </NavItem>
            :false}
            <NavItem>
              <NavLink href="/#/logout">Logout</NavLink>
            </NavItem>
          </Nav>
          {/* <NavbarText>Simple Text</NavbarText> */}
        </Collapse>
      </Navbar>
      <div className="main">
            <Switch>
                <Route exact path="/admin" render={props=><Dashboard {...props}/>}/>
                <Route path="/admin/artikel" render={props=><Artikel {...props}/>}/>
                <Route path="/admin/approve" render={props=><Approve {...props}/>}/>
                {this.state.role_id==4?
                  <Route path="/admin/user" render={props=><User {...props}/>}/>
                :false}
            </Switch>
      </div>
    </div>
        )
    }
}