import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Breadcaump from "../../components/Breadcaump";
import BlogPost from "../../blog/components/BlogPost";
import Login from "../../components/Login";
import DataTable from "react-data-table-component";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Col,
  Progress,
  ModalFooter
} from "reactstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import storage from "./firebase";
import {encrypt, url} from '../../brosky';
import Axios from "axios";

const uid={};
export default class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "ID", selector: "id", sortable: true, maxWidth: '50px' },
        { name: "Nama", selector: "nama", sortable: true },
        { name: "Username", selector: "username", sortable: true },
        { name: "Divisi", selector: "divisi", sortable: true, maxWidth: '150px' },
        { name: "Hak Akses", selector: "role", sortable: true, maxWidth: '150px' },
        { name: "Last Update", selector: "update_by", sortable: true, maxWidth: '150px' },
        { name: "Status", selector: "status", sortable: true, maxWidth: '150px', cell:row=>{
          return(
          <p>{row.status=='A'? 'Active':'Inactive'}</p>
          )
        } },
        {
          name: "Actions",
          selector: "actions",
          button: true,
          ignoreRowClick: true, maxWidth: '250px'
        }
      ],
      data: [],
      data_role:[],
      data_divisi:[],
      divisi:'',
      role:'',
      isOpen: false,
      isOpenView: false,
      judul: "",
      artikel: "",
      thumbnail: "",
      is_private: "N",
      start_upload: false,
      progress: 0,
      berita_active: 0,
      berita_id: 0,
      previous_id:0,
      status: 'A',
      nama: '',
      username: '',
      password: ''
    };
  }
  componentDidMount(){
    let x = JSON.parse(localStorage.getItem("@uid"));
    uid.id = x[0].id;
    uid.divisi_id = x[0].divisi_id;
    uid.role_id = x[0].role_id;
    let query = "select * from view_user order by id desc";
    query = encrypt(query);
    Axios.post(url.insert,{
      query: query
    }).then(result=>{
      //console.log(result.data);
      result.data.map((value, index)=>{
        result.data[index].actions = <Button color="primary" onClick={()=>this.custom_click(index,value.id)}>Edit</Button>;
      });
      this.setState({ data: result.data});
    }).catch(err=>console.log(err));
    let query2="select id, nama from tbl_role order by id";
    query2 = encrypt(query2);
    Axios.post(url.insert,{
      query: query2
    }).then(result=>{
      this.setState({ data_role: result.data, role: result.data[0].id});
    }).catch(err=>console.log(err));
    let query3="select id, nama from tbl_divisi order by id";
    query3 = encrypt(query3);
    Axios.post(url.insert,{
      query: query3
    }).then(result=>{
      this.setState({ data_divisi: result.data, divisi: result.data[0].id});
    }).catch(err=>console.log(err));
  }
  update_progress=(value)=>{
    this.setState({ progress: value});
  }
  
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      nama: "",
      modalUpdate: false
    });
  };
  edit=()=>{
    if(this.state.nama == '') alert('Nama harus diisi');
    else if(this.state.username =='') alert('Username harus diisi.');
    else if(this.state.password.length < 8) alert('Password setidaknya harus sepanjang 8 karakter atau lebih');
    else{
      console.log('else');
      let query=`update tbl_user set nama='${this.state.nama}', username='${this.state.username.toLowerCase()}', password='${this.state.password}', divisi_id='${this.state.divisi}', role_id='${this.state.role}', create_by=${uid.id}, update_by=${uid.id}, status='${this.state.status}' where id=${this.state.berita_id}`;
      //console.log(query);
      query = encrypt(query);
      Axios.post(url.insert,{
        query: query
      }).then(result=>{
        alert('User berhasil diupdate.');
        window.location.reload();
      }).catch(err=>{
        console.log(err);
        alert('Gagal Update User!');

      });
    }
  }
  toggleView = () => {
    this.setState({
      isOpenView: !this.state.isOpenView
    });
  };
  // validateusername=(username)=>{
  //   var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(username).toLowerCase());
  // }
  tambah=()=>{
    
    if(this.state.nama == '') alert('Nama harus diisi');
    else if(this.state.username =='') alert('Username harus diisi.');
    else if(this.state.password.length < 8) alert('Password setidaknya harus sepanjang 8 karakter atau lebih');
    else{
      let query=`insert into tbl_user (nama, username, password, divisi_id, role_id, create_by, update_by, status)
      values('${this.state.nama}', '${this.state.username.toLowerCase()}', '${this.state.password}', ${this.state.divisi}, ${this.state.role}, ${uid.id}, ${uid.id}, '${this.state.status}')`;
      query = encrypt(query);
    Axios.post(url.insert,{
      query: query
    }).then(result=>{
      alert('User berhasil ditambahkan.');
      window.location.reload();
    }).catch(err=>{
      console.log(err);
      alert('Gagal Menambahkan User!');

    });
    }
  }
  custom_click=(index,berita_id)=>{
    this.setState({ berita_active: index, berita_id: berita_id, isOpenView: true,
      nama: this.state.data[index].nama,
      username: this.state.data[index].username,
    });
  }
  render() {
    return (
      <div className="datatable-container">
        {/* <div style={{backgroundColor: 'red'}}>
          {this.state.data.length > 0? <div dangerouslySetInnerHTML={{ __html: this.state.data[0].berita }} />: false}
        </div> */}
        <div className="right">
          <Button color="success" onClick={this.toggle} className="right">
            Add New User
          </Button>
          <Input
            type="text"
            name="search"
            placeholder="Search"
            onChange={input => this.search(input)}
          />
        </div>
        <DataTable
          title="User"
          columns={this.state.columns}
          data={this.state.data}
          striped
          highlightOnHover
          pagination
          style={{ backgroundColor: "#ffffff" }}
        />
        <Modal isOpen={this.state.isOpen} toggle={this.state.toggle} size="lg">
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="nama" sm={2}>
                  Nama
                </Label>
                <Col sm={10}>
                  <Input
                    type="text"
                    name="nama"
                    placeholder="Nama Anda"
                    onChange={input =>
                      this.setState({ nama: input.target.value })
                    }
                    value={this.state.nama}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleusername" sm={2}>
                  Username
                </Label>
                <Col sm={10}>
                  <Input
                    name="username"
                    id="exampleusername"
                    placeholder="username"
                    onChange={input =>
                      this.setState({ username: input.target.value })
                    }
                    value={this.state.username}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleusername" sm={2}>
                  Password
                </Label>
                <Col sm={10}>
                  <Input
                    type="password"
                    name="password"
                    placeholder="********"
                    onChange={input =>
                      this.setState({ password: input.target.value })
                    }
                    value={this.state.password}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleusername" sm={2}>
                  Divisi
                </Label>
                <Col sm={10}>
                <Input type="select" name="select" id="exampleSelect" onChange={input=>{this.setState({ divisi: input.target.value}); }}>
                  {this.state.data_divisi.map((value,index)=>{
                    return(
                      <option value={value.id}>{value.nama}</option>
                    )
                  })}
                </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleusername" sm={2}>
                  Hak Akses
                </Label>
                <Col sm={10}>
                <Input type="select" name="select" id="exampleSelect" onChange={input=>{this.setState({ role: input.target.value});}}>
                  {this.state.data_role.map((value,index)=>{
                    return(
                      <option value={value.id}>{value.nama}</option>
                    )
                  })}
                </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleusername" sm={2}>
                  Status
                </Label>
                <Col sm={10}>
                <Input type="select" name="select" id="exampleSelect" onChange={input=>{this.setState({ status: input.target.value});}}>
                  <option value="A">Active</option>
                  <option value="I">Inactive</option>
                </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={10}>
                  {this.state.modalUpdate ? (
                    <Button onClick={this.update}>Update</Button>
                  ) : (
                    <Button color="primary" onClick={this.tambah}>Tambah</Button>
                  )}
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {this.state.data.length > 0? 
        <Modal isOpen={this.state.isOpenView} toggle={this.state.toggleView} size="lg">
          <ModalHeader toggle={this.toggleView}>{this.state.data[this.state.berita_active].nama}</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="nama" sm={2}>
                  Nama
                </Label>
                <Col sm={10}>
                  <Input
                    type="text"
                    name="nama"
                    placeholder="Nama Anda"
                    onChange={input =>
                      this.setState({ nama: input.target.value })
                    }
                    value={this.state.nama}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleusername" sm={2}>
                  Username
                </Label>
                <Col sm={10}>
                  <Input
                    name="username"
                    id="exampleusername"
                    placeholder="username"
                    onChange={input =>
                      this.setState({ username: input.target.value })
                    }
                    value={this.state.username}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleusername" sm={2}>
                  Password
                </Label>
                <Col sm={10}>
                  <Input
                    type="password"
                    name="password"
                    placeholder="********"
                    onChange={input =>
                      this.setState({ password: input.target.value })
                    }
                    value={this.state.password}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleusername" sm={2}>
                  Divisi
                </Label>
                <Col sm={10}>
                <Input type="select" name="select" id="exampleSelect" onChange={input=>{this.setState({ divisi: input.target.value}); }}>
                  {this.state.data_divisi.map((value,index)=>{
                    return(
                      <option value={value.id}>{value.nama}</option>
                    )
                  })}
                </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleusername" sm={2}>
                  Hak Akses
                </Label>
                <Col sm={10}>
                <Input type="select" name="select" id="exampleSelect" onChange={input=>{this.setState({ role: input.target.value});}}>
                  {this.state.data_role.map((value,index)=>{
                    return(
                      <option value={value.id}>{value.nama}</option>
                    )
                  })}
                </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleusername" sm={2}>
                  Status
                </Label>
                <Col sm={10}>
                <Input type="select" name="select" id="exampleSelect" onChange={input=>{this.setState({ status: input.target.value});}}>
                  <option value="A">Active</option>
                  <option value="I">Inactive</option>
                </Input>
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleView}>Exit</Button>
            <Button color="primary" onClick={this.edit}>Update</Button>
          </ModalFooter>
        </Modal>
        :false}
      </div>
    );
  }
}
