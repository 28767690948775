import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Breadcaump from "../../components/Breadcaump";
import Login from "../../components/Login";
import { Redirect, Route, Switch } from 'react-router-dom';
import Logout from "../Admin/Logout";
import CardBerita from "./CardBerita";
import { encrypt, url } from "../../brosky";
import Axios from "axios";
import CardLogin from "../../components/CardLogin";
import CardHome from "../../components/CardHome";
import { Button, Input, ModalHeader, ModalBody, Form, FormGroup, Label, Col, ModalFooter, Modal } from "reactstrap";
import DataTable from "react-data-table-component";

let uid={}
export default class HomePage extends Component{
    constructor(props){
        super(props);
        this.state={
            columns: [
                { name: "ID", selector: "id", sortable: true, maxWidth: '50px' },
                { name: "Judul", selector: "nama", sortable: true },
                // { name: "Preview", selector: "berita", sortable: true, maxWidth: '350px',style:{maxHeight: '10px'},cell: (row)=>{return(
                //   <div dangerouslySetInnerHTML={{ __html: row.berita }} />
                // )
                // } },
                { name: "Status", selector: "active", sortable: true, maxWidth: '150px', cell: (row)=>{
                  return(
                    <p className={row.active=='A'? 'Active':row.active=='I'?'Inactive':'Rejected'}>{row.active=='A'? 'Active':row.active=='I'?'Inactive':'Rejected'}</p>
                  )
                } },
                { name: "Last Approval", selector: "last_approval_role", sortable: true, maxWidth: '150px' },
                { name: "Author", selector: "create_by_name", sortable: true, maxWidth: '150px' },
                { name: "Divisi", selector: "divisi", sortable: true, maxWidth: '150px' },
                {
                  name: "Actions",
                  selector: "actions",
                  button: true,
                  ignoreRowClick: true, maxWidth: '250px'
                }
              ],
              data: [],
              master: [], 
              berita_active: 0,
              isOpenView: false,
              data_divisi: [],
              komentar: []
        }
    }
    toggleView = () => {
      this.setState({
        isOpenView: !this.state.isOpenView
      });
    }
    custom_click=(index,berita_id)=>{
      this.setState({ berita_active: index, berita_id: berita_id, isOpenView: true});
      let query = "select nama, bagian, komentar, to_char(create_date, 'dd-mm-yyyy HH24:MI') as tanggal from tbl_komentar where berita_id="+berita_id+" order by id";
          query = encrypt(query);
          Axios.post(url.insert,{
              query: query
          }).then(result=>{
              //console.log(result.data);
              this.setState({ komentar: result.data});
          }).catch(err=>console.log(err));
    }
    componentDidMount(){
      let query3="select id, nama from tbl_divisi order by id";
        query3 = encrypt(query3);
        Axios.post(url.insert,{
            query: query3
        }).then(result=>{
            this.setState({ data_divisi: result.data});
        }).catch(err=>console.log(err));
        let x = JSON.parse(localStorage.getItem("@uid"));
        if(x==null) uid={};
        else uid.divisi_id=x[0].divisi_id;
        let query = 'select * from view_berita where divisi_id=' + this.props.divisi_id+" and active='A'";
        if (x==null) query += " and is_private='N'";
        else{
          if(uid.divisi_id==7 || x[0].role_id==4);
          else query += " and is_private='N'";
        }
        query=encrypt(query);
        Axios.post(url.insert,{
            query: query
          }).then(result=>{
            //console.log(result.data);
            result.data.map((value, index)=>{
              result.data[index].actions = <Button color="primary" onClick={()=>this.custom_click(index,value.id)}>View</Button>;
            });
            console.log(result.data);
            this.setState({ data: result.data, master: result.data});
          }).catch(err=>console.log(err));
    }
    search=(input)=>{
      let x = this.state.master.filter((value)=>{  
        return value.nama.toLowerCase().includes(input.target.value.toLowerCase())
      });
      this.setState({data: x});
    }
    kirim=()=>{
      if(this.state.nama == '') alert('Silahkan isi nama anda terlebih dahulu');
      else if(this.state.divisi == '') alert('Silahkan pilih divisi terlebih dahulu.');
      else if(this.state.inputkomentar == '') alert('Anda belum memasukan komentar');
      else{
        let query = `insert into tbl_komentar (berita_id, nama, bagian, komentar, create_by, update_by) values(${this.state.berita_id}, '${this.state.nama}', '${this.state.divisi}', '${this.state.inputkomentar}', 0,0)`;
        //console.log(query);
        query=encrypt(query);
        Axios.post(url.insert,{
            query: query
        }).then(result=>{
            //console.log(result);
            alert('Komentar berhasil ditambahkan.');
            window.location.reload();
        }).catch(err=>{
            console.log(err)
            alert('Gagal menambahkan komen. Silahkan coba kembali.');
        });
      }
    }
    render(){
        return(
            <div className="list-artikel">
                <div className="datatable-container">
                    {/* <div style={{backgroundColor: 'red'}}>
                    {this.state.data.length > 0? <div dangerouslySetInnerHTML={{ __html: this.state.data[0].berita }} />: false}
                    </div> */}
                    <div className="right">
                      <p>{this.props.title}: {this.state.master.length} Artikel</p>
                      <Input
                          type="text"
                          name="search"
                          placeholder="Search"
                          onChange={input => this.search(input)}
                      />
                    </div>
                    <DataTable
                        title={'ARTIKEL ' + this.props.title}
                        columns={this.state.columns}
                        data={this.state.data}
                        striped
                        highlightOnHover
                        pagination
                        style={{ backgroundColor: "#ffffff" }}
                    />
                    {this.state.data.length > 0? 
        <Modal isOpen={this.state.isOpenView} toggle={this.state.toggleView} size="lg">
          <ModalHeader toggle={this.toggleView}>{this.state.data[this.state.berita_active].nama}</ModalHeader>
          <ModalBody>
          <Form>
              <FormGroup row>
                <Label for="exampleEmail" sm={2}>
                  Thumbnail
                </Label>
                <Col sm={10}>
                  <img src={this.state.data[this.state.berita_active].url_foto} className="thumbnail"/>
                </Col>
              </FormGroup>
            </Form>
            <h4>Informasi: </h4>
            <div dangerouslySetInnerHTML={{ __html: this.state.data[this.state.berita_active].berita }} />
            <div className="separator"></div>
                            <div className="inputkomentar">
                                <p>FEEDBACK</p>
                                <Input type="text" placeholder="Nama" onChange={input=>this.setState({ nama: input.target.value})}/>
                                <Input type="select" name="select" id="exampleSelect" onChange={input=>{this.setState({ divisi: input.target.value}); }}>
                                    <option value="-">Nama Divisi</option>
                                {this.state.data_divisi.map((value,index)=>{
                                    return(
                                    <option value={value.nama}>{value.nama}</option>
                                    )
                                })}
                                </Input>
                                <Input
                                    type="textarea"
                                    placeholder="Masukan Feedback anda disini..."
                                    onChange={input=>this.setState({ inputkomentar: input.target.value})}
                                />
                            </div>
                            <div style={{width: '100%', textAlign: 'right', marginTop: 10}}>
                                <Button color="primary" onClick={this.kirim}>Kirim</Button>
                            </div>
                            <div className="komentarbox">
                                {this.state.komentar.map((value,index)=>{
                                    return(
                                    <div className="komentarrow" key={index}>
                                        <div className="rowbro">
                                            <p className="h5">{value.nama}</p>
                                            <p className="role">{value.bagian}</p>
                                            <p className="waktu">{value.tanggal}</p>
                                        </div>
                                        <p className="komentar">{value.komentar}</p>
                                    </div>
                                    )
                                })}
                            </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={this.toggleView}>Exit</Button>
                    <Button color="primary" onClick={this.edit}>Edit</Button>
                  </ModalFooter>
                </Modal>
                :false}
                </div>
            </div>
        )
    }
}