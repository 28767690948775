import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Breadcaump from "../../components/Breadcaump";
import BlogPost from "../../blog/components/BlogPost";
import Login from "../../components/Login";
import { Redirect, Route, Switch } from 'react-router-dom';
import Logout from "../Admin/Logout";
import {
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Col,
    Progress,
    ModalFooter
  } from "reactstrap";
import { encrypt, url } from "../../brosky";
import Axios from "axios";

export default class CardBerita extends Component{
    constructor(props){
        super(props);
        this.state={
            isOpenView: false,
            komentar: [],
            data_divisi: [],
            divisi: '',
            inputkomentar: '',
            nama: ''
        }
    }
    componentDidMount(){
        let query3="select id, nama from tbl_divisi order by id";
        query3 = encrypt(query3);
        Axios.post(url.insert,{
            query: query3
        }).then(result=>{
            this.setState({ data_divisi: result.data});
        }).catch(err=>console.log(err));
        let query = "select nama, bagian, komentar, to_char(create_date, 'dd-mm-yyyy HH24:MI') as tanggal from tbl_komentar where berita_id="+this.props.data.id+" order by id";
        query = encrypt(query);
        Axios.post(url.insert,{
            query: query
        }).then(result=>{
            //console.log(result.data);
            this.setState({ komentar: result.data});
        }).catch(err=>console.log(err));
    }
    toggleView=()=>{
        this.setState({ isOpenView: !this.state.isOpenView});
    }
    kirim=()=>{
        if(this.state.nama == '') alert('Silahkan isi nama anda terlebih dahulu');
        else if(this.state.divisi == '') alert('Silahkan pilih divisi terlebih dahulu.');
        else if(this.state.inputkomentar == '') alert('Anda belum memasukan komentar');
        else{
            let query = `insert into tbl_komentar (berita_id, nama, bagian, komentar, create_by, update_by) values(${this.props.data.id}, '${this.state.nama}', '${this.state.divisi}', '${this.state.inputkomentar}', 0,0)`;
            //console.log(query);
            query=encrypt(query);
            Axios.post(url.insert,{
                query: query
            }).then(result=>{
                //console.log(result);
                alert('Komentar berhasil ditambahkan.');
                window.location.reload();
            }).catch(err=>{
                console.log(err)
                alert('Gagal menambahkan komen. Silahkan coba kembali.');
            });
        }
    }
    render(){
        let val = this.props.data;
        return(
            <div className="col-lg-4 col-md-6 col-12">
                    <div className="blog">
                        <div className="thumb">
                            <a href={val.link}><img className="w-100"  src={`${val.url_foto}`} alt="Images"/></a>
                        </div>
                        <div className="inner inner2">
                            <h4 className="title">{val.nama}</h4>
                            <ul className="meta">
                                <li>{val.create_date}</li>
                                <li>By <a href="/">{val.create_by_name}</a></li>
                            </ul>
                            <div className="desc mt--10 mb--30" dangerouslySetInnerHTML={{ __html: val.berita }} />
                            <div className="blog-btn">
                                <button className="button-link" onClick={this.toggleView}>Read More</button>
                            </div>
                        </div>
                    </div>
                    <Modal isOpen={this.state.isOpenView} toggle={this.state.toggleView} size="lg">
                        <ModalHeader toggle={this.toggleView}>{val.nama}</ModalHeader>
                        <ModalBody className="berita-modal">
                            <div dangerouslySetInnerHTML={{ __html: val.berita }} />
                            <div className="separator"></div>
                            <div className="inputkomentar">
                                <p>FEEDBACK</p>
                                <Input type="text" placeholder="Nama" onChange={input=>this.setState({ nama: input.target.value})}/>
                                <Input type="select" name="select" id="exampleSelect" onChange={input=>{this.setState({ divisi: input.target.value}); }}>
                                    <option value="-">Nama Divisi</option>
                                {this.state.data_divisi.map((value,index)=>{
                                    return(
                                    <option value={value.nama}>{value.nama}</option>
                                    )
                                })}
                                </Input>
                                <Input
                                    type="textarea"
                                    placeholder="Masukan Feedback anda disini..."
                                    onChange={input=>this.setState({ inputkomentar: input.target.value})}
                                />
                            </div>
                            <div style={{width: '100%', textAlign: 'right', marginTop: 10}}>
                                <Button color="primary" onClick={this.kirim}>Kirim</Button>
                            </div>
                            <div className="komentarbox">
                                {this.state.komentar.map((value,index)=>{
                                    return(
                                    <div className="komentarrow" key={index}>
                                        <div className="rowbro">
                                            <p className="h5">{value.nama}</p>
                                            <p className="role">{value.bagian}</p>
                                            <p className="waktu">{value.tanggal}</p>
                                        </div>
                                        <p className="komentar">{value.komentar}</p>
                                    </div>
                                    )
                                })}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.toggleView}>Exit</Button>
                        </ModalFooter>
                    </Modal>
                </div>
        )
    }
}