import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Breadcaump from "../../components/Breadcaump";
import BlogPost from "../../blog/components/BlogPost";
import Login from "../../components/Login";
import {
    Container, Col, Form,
    FormGroup, Label, Input,
    Button,
  } from 'reactstrap';
//   import './App.css';
import Axios from 'axios';
import {encrypt, url} from "../../brosky";

export default class LoginPage extends Component{
    constructor(props){
        super(props);
        this.state={
            username: '',
            password: ''
        }
    }
    submit=()=>{
        // this.props.history.push('/admin');
        let query = "select id, divisi_id, role_id from tbl_user where username = '"+ this.state.username.toLowerCase() + "' and password = '"+this.state.password+"'";
        query = encrypt(query);
        //console.log(query);
        Axios.post(url.insert,{
            query: query
        }).then(result=>{
            if(result.data.length == 0) alert('Username atau Password salah.');
            else if(this.props.divisi_id == result.data[0].divisi_id){
                localStorage.setItem('@uid', JSON.stringify(result.data));
                this.props.history.push('/admin');
            }
            else{
                let x = result.data[0].divisi_id;
                if(x==1) alert('Divisi Umum tidak dapat login dari halaman login ' + this.props.title);
                else if(x==1) alert('Divisi Umum tidak dapat login dari halaman login ' + this.props.title);
                else if(x==2) alert('Divisi ICT tidak dapat login dari halaman login ' + this.props.title);
                else if(x==3) alert('Divisi BAK tidak dapat login dari halaman login ' + this.props.title);
                else if(x==4) alert('Divisi Pembelian dan Percetakan tidak dapat login dari halaman login ' + this.props.title);
                else if(x==5) alert('Divisi Personalia tidak dapat login dari halaman login ' + this.props.title);
                else if(x==6) alert('Divisi RTU tidak dapat login dari halaman login ' + this.props.title);
                else if(x==7) alert('Divisi SATPAM tidak dapat login dari halaman login ' + this.props.title);
                else if(x==8) alert('Divisi Operator Telephone tidak dapat login dari halaman login ' + this.props.title);
            }
        }).catch(err=>{ console.log(err); alert("Terjadi Kesalahan.")});
        console.log(this.state);
    }
    componentDidMount(){
        console.log('props:' + JSON.stringify(this.props));
        if(localStorage.getItem("@uid") != null) this.props.history.push("/admin");
    }
    setValue=(name, input)=>{
        this.setState({ [name]: input.target.value});
    }
    render(){
        return(
        <div className="center login-page">
            <Container className="App center">
                <a href="/">
                    <img src="/assets/images/logo/logo.png" style={{width: '30%'}}></img>
                </a>
                <h3>Knowledge Management System</h3>
                <h5>{this.props.title}</h5>
                <Form className="form" method="post">
                <Col>
                    <FormGroup>
                    <Label>Username</Label>
                    <Input
                        name="username"
                        id="exampleEmail"
                        placeholder="username"
                        onChange={(input)=>this.setValue('username', input)}
                    />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                    <Label for="examplePassword">Password</Label>
                    <Input
                        type="password"
                        name="password"
                        id="examplePassword"
                        placeholder="********"
                        onChange={(input)=>this.setValue('password', input)}
                    />
                    </FormGroup>
                </Col>
                <Button color="primary" type="button" onClick={this.submit}>Submit</Button>
                </Form>
            </Container>
            <a href="/#/login/">Select Login Page</a>
        </div>
        )
    }
}