import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Breadcaump from "../../components/Breadcaump";
import BlogPost from "../../blog/components/BlogPost";
import Login from "../../components/Login";
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import ICT from "./ICT";
import Logout from "../Admin/Logout";
import Umum from "./Umum";
import BAAK from "./BAAK";
import LoginContainer from "./LoginContainer";
import Pembelian from "./Pembelian";
import Personalia from "./Personalia";
import RTU from "./RTU";
import SATPAM from "./SATPAM";
import Telephone from "./Telephone";
import HomeContainer from "./HomeContainer";
import HomePage from "./HomePage";

let uid={}
let data=[
    {
        nama: 'Umum',
        url: 'umum',
        divisi_id: 1
    },
    {
        nama: 'ICT',
        url: 'ict',
        divisi_id: 2
    },
    {
        nama: 'BAK',
        url: 'bak',
        divisi_id: 3
    },
    {
        nama: 'Pembelian dan Percetakan',
        url: 'pembelian',
        divisi_id: 4
    },
    {
        nama: 'Personalia',
        url: 'personalia',
        divisi_id: 5
    },
    {
        nama: 'Rumah Tangga',
        url: 'rumah_tangga',
        divisi_id: 6
    },
    {
        nama: 'Satuan Pengamanan',
        url: 'satpam',
        divisi_id: 7
    },
    {
        nama: 'Operator Telephone',
        url: 'operator_telephone',
        divisi_id: 8
    },
];
class Home extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){
        
    }
    render(){
        return(
            <div>
                <Navbar/>
                <Breadcaump/>
                <Switch>
                    <Route path="/umum" render={props=><Umum {...props}/>}/>
                    <Route path="/ict" render={props=><ICT {...props}/>}/>
                    <Route path="/bak" render={props=><BAAK {...props}/>}/>
                    <Route path="/pembelian-dan-percetakan" render={props=><Pembelian {...props}/>}/>
                    <Route path="/personalia" render={props=><Personalia {...props}/>}/>
                    <Route path="/rumah-tangga" render={props=><RTU {...props}/>}/>
                    <Route path="/satuan-pengamanan" render={props=><SATPAM {...props}/>}/>
                    <Route path="/operator-telephone" render={props=><Telephone {...props}/>}/>
                    <Route path="/login" render={props=><LoginContainer {...props}/>}/>
                    <Route path="/logout" render={props=><Logout {...props}/>}/>
                    {data.map((value, index)=>{
                        return(
                            <Route exact path={`${process.env.PUBLIC_URL}/home/${value.url}`} render={props=> <HomePage title={value.nama} divisi_id={value.divisi_id} {...props}/>}/>    
                        )
                    })}
                    <Route exact path="/" render={props=><HomeContainer {...props}/>}/>
                </Switch>
                {/* <BlogPost/> */}
                <Login/>
            </div>
        )
    }
}

export default withRouter(Home);