import React, { Component } from "react";

let uid={}
export default class CardLogin extends Component{
    constructor(props){
        super(props);
        this.state={
            data:[]
        }
    }
    componentDidMount(){
    }
    render(){
        let val = {}
        return(
            <React.Fragment>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="blog">
                        {/* <div className="thumb">
                            <a href={val.link}><img className="w-100"  src={`${val.url_foto}`} alt="Images"/></a>
                        </div> */}
                        <div className="inner inner2">
                            <h4 className="title">{this.props.data.nama}</h4>
                            {/* <ul className="meta">
                                <li>{val.create_date}</li>
                                <li>By <a href="/">{val.create_by_name}</a></li>
                            </ul> */}
                            <div className="blog-btn">
                                <a href={"#/login/"+this.props.data.url} className="button-link">Login</a>
                                {/* <button  onClick={this.move}>{this.props.data.nama}</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}