import React ,  { Component } from "react";
import { withRouter, Link } from "react-router-dom";

class Navbar extends Component {
    resetHref() {
        window.location.hash = '';
    }
    render(){
        return(
            <div className="app-header header--transparent sticker" id="main-menu">
                <div className="container">
                    <div className="row align-items-center navbar">
                    {/* <p className="navbar-image">Kwik Kian Gie</p> */}
                        <div className="navbar-image-container">
                            <img className="navbar-image" src="/assets/images/logo/logo.png" alt="app landing"/>
                        </div>
                        <div className="col-lg-2 col-sm-4 col-4">
                            <div className="logo">
                                <a href={`${process.env.PUBLIC_URL}/`}>
                                    <img className="logo-1" src="/assets/images/logo/logo.png" alt="app landing"/>
                                    <img className="logo-2" src="/assets/images/logo/logo.png" alt="app landing"/>
                                    <img className="logo-3" src="/assets/images/logo/logo.png" alt="app landing"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-10 d-none d-lg-block">
                            <div className="mainmenu-wrapper">
                                <nav>
                                    <ul className="main-menu">
                                        <li className="active"><a href="/">Home</a></li>
                                        <li><a href="/#umum">Umum</a></li>
                                        <li><a href="/#ict">ICT</a></li> 
                                        <li><a href="/#bak">BAK</a></li>
                                        <li><a href="/#pembelian-dan-percetakan">Pembelian dan Percetakan</a></li>
                                        <li><a href="/#personalia">Personalia</a></li>
                                        <li><a href="/#rumah-tangga">Rumah Tangga</a></li>
                                        <li><a href="/#satuan-pengamanan">Satuan Pengamanan</a></li>
                                        <li><a href="/#operator-telephone">Operator Telephone</a></li>
                                        <li><a href="/#login">Login</a></li>
                                        {/* <li><a href="#operator-telephone">Login</a></li> */}
                                    </ul>
                                </nav>
                                {/* <button className="button-default button-olive" type="button">Download</button> */}
                            </div>
                        </div>
                        <div className="col-sm-7 col-7 d-block d-lg-none">
                            <div className="mobile-menu">
                                <nav>
                                    <ul>                              
                                        <li className="active"><a href="/">Home</a></li>
                                        <li><a href="/#umum">Umum</a></li>
                                        <li><a href="/#ict">ICT</a></li>
                                        <li><a href="/#bak">BAK</a></li>
                                        <li><a href="/#pembelian-dan-percetakan">Pembelian dan Percetakan</a></li>
                                        <li><a href="/#personalia">Personalia</a></li>
                                        <li><a href="/#rumah-tangga">Rumah Tangga</a></li>
                                        <li><a href="/#satuan-pengamanan">Satuan Pengamanan</a></li>
                                        <li><a href="/#operator-telephone">Operator Telephone</a></li>
                                        <li><a href="/#login">Login</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar;


