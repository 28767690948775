import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Breadcaump from "../../components/Breadcaump";
import BlogPost from "../../blog/components/BlogPost";
import Login from "../../components/Login";
import { encrypt, url } from "../../brosky";
import Axios from "axios";
import CardBerita from "../Home/CardBerita";
let uid={};
export default class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state={
            data:[],
            y: '',
            berita:[],
            jumlah_artikel: {}
        }
    }
    getBerita=()=>{
        let x = JSON.parse(localStorage.getItem("@uid"));
        uid.id = x[0].id;
        uid.divisi_id = x[0].divisi_id;
        uid.role_id = x[0].role_id;
        let query = "select * from view_berita";
        // if(uid.role_id <3) 
        query+= " where create_by="+ uid.id + "";
        query += " order by id desc";
        //console.log(query);
        query = encrypt(query);
        Axios.post(url.insert,{
            query: query
        }).then(result=>{
            //console.log(result.data);
            this.setState({ berita: result.data});
        }).catch(err=>console.log(err));
    }
    componentDidMount(){
        let x = JSON.parse(localStorage.getItem('@uid'));
        let query = "select * from view_user where id = " + x[0].id;
        //console.log(query);
        query = encrypt(query);
        Axios.post(url.insert,{
            query: query
        }).then(result=>{
            //console.log(result.data);
            this.setState({ data: result.data[0]});
        }).catch(err=>console.log(err));
        let query2=`select (select count(1) from tbl_berita where create_by=${x[0].id}) as user, (select count(1) from tbl_berita where divisi_id=${x[0].divisi_id}) as divisi`;
        query2=encrypt(query2);
        Axios.post(url.insert,{
            query: query2
        }).then(result=>{
            //console.log(result.data);
            this.setState({ jumlah_artikel: result.data[0]});
        }).catch(err=>{
            console.log(err);
        })
        this.getBerita();
        setInterval(()=>{
            let y = new Date();
            let tanggal = y.getDate();
            if(tanggal<10) tanggal='0'+tanggal;
            let bulan = y.getMonth() + 1;
            if(bulan<10) bulan='0'+bulan;
            let tahun = y.getFullYear();
            let jam = y.getHours();
            let keterangan='';
            if(jam<6) keterangan='Selamat Pagi';
            else if(jam<10) keterangan='Selamat Pagi';
            else if(jam<15) keterangan='Selamat Siang';
            else if(jam < 18) keterangan='Selamat Sore';
            else keterangan='Selamat Malam';
            if(jam<10) jam = '0'+jam;
            let menit = y.getMinutes();
            if(menit<10) menit='0'+menit;
            let detik = y.getSeconds();
            if(detik<10) detik='0'+detik;
            let waktu = tanggal + '-' + bulan + '-' + tahun + ' ' + jam + ':'+menit+':'+detik;
            this.setState({waktu: waktu, keterangan: keterangan});
        }, 100);
    }
    
    render(){
        return(
            <div>
                <p>{this.state.waktu}</p>
                <h2>{this.state.keterangan} {this.state.data.nama},</h2>
                <h5>Welcome to Knowledge Management System Institut Bisnis dan Informatika Kwik Kian Gie</h5>
                <table style={{maxWidth: 300}}>
                    <tbody>
                        <tr>
                            <td>Nama</td>
                            <td>{this.state.data.nama}</td>
                        </tr>
                        <tr>
                            <td>Divisi</td>
                            <td>{this.state.data.divisi}</td>
                        </tr>
                        <tr>
                            <td>Hak Akses</td>
                            <td>{this.state.data.role}</td>
                        </tr>
                        <tr>
                            <td>Status Akun</td>
                            <td>{this.state.data.status=='A'? 'Aktif':'Tidak Aktif'}</td>
                        </tr>
                        <tr>
                            <td>Jumlah Artikel Individu</td>
                            <td>{this.state.jumlah_artikel.user}</td>
                        </tr>
                        <tr>
                            <td>Jumlah Artikel Divisi</td>
                            <td>{this.state.jumlah_artikel.divisi}</td>
                        </tr>
                    </tbody>
                </table>
                <h3 className="center">List Berita</h3>
                <div className="row center" style={{marginLeft: 0, marginRight: 0, }}>
                    {this.state.berita.length == null ? <p>Loading...</p>: false}
                    {this.state.berita.map((value, index)=>{
                        return(
                            <CardBerita key={index} data={value}/>
                        )
                    })}
                </div>
            </div>
        )
    }
}