// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';
import Demo from './page-demo/Demo';
import HomeOlive from './HomeOlive';
import HomeHorizontal from './HomeHorizontal';
import BlogGrid from './blog/BlogGrid';

import BlogTwoColumn from './blog/BlogTwoColumn';
import BlogDetails from './blog/BlogDetails';
import Home from './screens/Home';


import {BrowserRouter, Switch, Route, HashRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import LoginPage from './screens/LoginPage';
import Admin from './screens/Admin';
let data=[
    {
        nama: 'Umum',
        url: 'umum',
        divisi_id: 1
    },
    {
        nama: 'ICT',
        url: 'ict',
        divisi_id: 2
    },
    {
        nama: 'BAK',
        url: 'bak',
        divisi_id: 3
    },
    {
        nama: 'Pembelian dan Percetakan',
        url: 'pembelian',
        divisi_id: 4
    },
    {
        nama: 'Personalia',
        url: 'personalia',
        divisi_id: 5
    },
    {
        nama: 'Rumah Tangga',
        url: 'rumah_tangga',
        divisi_id: 6
    },
    {
        nama: 'Satuan Pengamanan',
        url: 'satpam',
        divisi_id: 7
    },
    {
        nama: 'Operator Telephone',
        url: 'operator_telephone',
        divisi_id: 8
    },
];
class Root extends Component{
    render(){
        return(
            // <BrowserRouter basename={'/'}>
            <HashRouter>
                <Switch>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Demo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/home-one`} component={HomeOlive}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/home-two`} component={HomeHorizontal}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-grid`} component={BlogGrid}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-two-column`} component={BlogTwoColumn}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/> */}
                    {data.map((value, index)=>{
                        return(
                            <Route path={`${process.env.PUBLIC_URL}/login/${value.url}`} render={props=> <LoginPage title={value.nama} divisi_id={value.divisi_id} {...props}/>}/>    
                        )
                    })}
                    {/* <Route path={`${process.env.PUBLIC_URL}/login/umum`} render={props=> <LoginPage title="Umum" {...props}/>}/>
                    <Route path={`${process.env.PUBLIC_URL}/login/ict`} render={props=> <LoginPage title="ICT" {...props}/>}/>
                    <Route path={`${process.env.PUBLIC_URL}/login/baak`} render={props=> <LoginPage title="BAAK" {...props}/>} />
                    <Route path={`${process.env.PUBLIC_URL}/login/pembelian`} render={props=> <LoginPage title="Pembelian dan Percetakan" {...props}/>} />
                    <Route path={`${process.env.PUBLIC_URL}/login/personalia`} render={props=> <LoginPage title="Personalia" {...props}/>} />
                    <Route path={`${process.env.PUBLIC_URL}/login/rumah_tangga`} render={props=> <LoginPage title="Rumah Tangga" {...props}/>} />
                    <Route path={`${process.env.PUBLIC_URL}/login/satpam`} render={props=> <LoginPage title="Satuan Pengamanan" {...props}/>} />
                    <Route path={`${process.env.PUBLIC_URL}/login/operator_telephone`} render={props=> <LoginPage title="Operator Telephone" {...props}/>} /> */}
                    <Route path={`${process.env.PUBLIC_URL}/admin`} render={props=> <Admin {...props}/>} />
                    <Route path={`${process.env.PUBLIC_URL}/`} render={props=> <Home {...props}/>} />
                </Switch>
            </HashRouter>
            // </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.unregister();


