import React ,  { Component } from "react";
class Login extends Component {
    render(){
        return(
            <a href="#/login">
                <div className="lock-container">
                    <i className="zmdi zmdi-lock full"></i>
                </div>
            </a>
        )
    }
}

export default Login;


