import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Breadcaump from "../../components/Breadcaump";
import BlogPost from "../../blog/components/BlogPost";
import Login from "../../components/Login";

export default class Logout extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    async componentDidMount(){
        await localStorage.clear();
        this.props.history.push("/");
    }
    
    render(){
        return(
            <div>
                <p>Getting you out</p>
            </div>
        )
    }
}