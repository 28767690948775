import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Breadcaump from "../../components/Breadcaump";
import BlogPost from "../../blog/components/BlogPost";
import Login from "../../components/Login";
import { Redirect, Route, Switch } from 'react-router-dom';
import Logout from "../Admin/Logout";
import CardBerita from "./CardBerita";
import { encrypt, url } from "../../brosky";
import Axios from "axios";

let uid={}
export default class Personalia extends Component{
    constructor(props){
        super(props);
        this.state={
            data:[],
            is_execute: false
        }
    }
    componentDidMount(){
        console.log(this.state.data);
        let x = JSON.parse(localStorage.getItem("@uid"));
        if(x==null) uid={};
        else uid.divisi_id=x[0].divisi_id;
        let query="select * from view_berita where active='A' and divisi_id=5";
        //console.log(query);
        if (x==null) query += " and is_private='N'";
        else{
            if(uid.divisi_id==5 || x[0].role_id==4);
            else query += " and is_private='N'";
        }
        query = encrypt(query);
        //console.log(query);
        Axios.post(url.insert,{
            query: query
        }).then(result=>{
        //console.log(result.data);
        this.setState({ data: result.data, is_execute: true});
        }).catch(err=>{
            console.log('error: ');
            console.log(err)
        });
    }

    render(){
        return(
            <React.Fragment>
                <div style={{width: '100%', textAlign: 'center', paddingTop: 50}}>
                    <h3>Personalia</h3>
                    {this.state.is_execute?<h5>{this.state.data.length} Artikel</h5>:false}
                </div>
                <div className="row center">
                    {this.state.data.map((value, index)=>{
                        return(
                            <CardBerita key={index} data={value}/>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }
}